exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-battery-swap-js": () => import("./../../../src/pages/battery-swap.js" /* webpackChunkName: "component---src-pages-battery-swap-js" */),
  "component---src-pages-car-scrapping-js": () => import("./../../../src/pages/car-scrapping.js" /* webpackChunkName: "component---src-pages-car-scrapping-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jump-start-service-js": () => import("./../../../src/pages/jump-start-service.js" /* webpackChunkName: "component---src-pages-jump-start-service-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-towing-service-js": () => import("./../../../src/pages/towing-service.js" /* webpackChunkName: "component---src-pages-towing-service-js" */),
  "component---src-pages-tyre-repairing-js": () => import("./../../../src/pages/tyre-repairing.js" /* webpackChunkName: "component---src-pages-tyre-repairing-js" */),
  "component---src-pages-tyre-swapping-js": () => import("./../../../src/pages/tyre-swapping.js" /* webpackChunkName: "component---src-pages-tyre-swapping-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-categorie-template-js": () => import("./../../../src/templates/categorie-template.js" /* webpackChunkName: "component---src-templates-categorie-template-js" */),
  "component---src-templates-feature-template-js": () => import("./../../../src/templates/feature-template.js" /* webpackChunkName: "component---src-templates-feature-template-js" */),
  "component---src-templates-project-template-js": () => import("./../../../src/templates/project-template.js" /* webpackChunkName: "component---src-templates-project-template-js" */),
  "component---src-templates-service-template-js": () => import("./../../../src/templates/service-template.js" /* webpackChunkName: "component---src-templates-service-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */)
}

